import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.5"
      height="31.5"
      viewBox="0 0 31.5 31.5"
    >
      <path
        fill="#fff"
        d="M28.125 2.25H3.375A3.376 3.376 0 000 5.625v24.75a3.376 3.376 0 003.375 3.375h24.75a3.376 3.376 0 003.375-3.375V5.625a3.376 3.376 0 00-3.375-3.375zm-3.438 11.166c.014.2.014.4.014.6a13.031 13.031 0 01-13.12 13.12 13.06 13.06 0 01-7.081-2.07 9.715 9.715 0 001.111.056 9.246 9.246 0 005.723-1.969 4.618 4.618 0 01-4.31-3.2 4.97 4.97 0 002.081-.084 4.612 4.612 0 01-3.691-4.528v-.056a4.608 4.608 0 002.086.585 4.6 4.6 0 01-2.058-3.84 4.554 4.554 0 01.626-2.33 13.094 13.094 0 009.506 4.823 4.621 4.621 0 017.868-4.212A9.036 9.036 0 0026.367 9.2a4.6 4.6 0 01-2.025 2.538A9.177 9.177 0 0027 11.025a9.707 9.707 0 01-2.313 2.391z"
        data-name="Icon awesome-twitter-square"
        transform="translate(0 -2.25)"
      ></path>
    </svg>
  );
}

export default Icon;
