import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"

import Section from './section';
import {P, H2} from './type';



const GigsStyled = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const GigStyled = styled.li`
    padding: 1.5em 0;
    
    
    border-bottom: 1px solid #9A9393;
    margin-bottom: 0;
    text-align: left;

    &:last-child{
        border-bottom: 0;
    }

    @media (min-width: 768px){
        display: flex;
        align-items: center;
    }
`

const GigDate = styled.span`
    font-size: 2.1em;
    padding-right: 30px;
    color: #9A9393;
    display: block;
    line-height: 1;
    margin-top: -.1em;
    margin-bottom: 1rem;

    @media (min-width: 768px){
        margin-bottom: 0;
    }

    @media (min-width: 960px){
        font-size: 3.1em;
    }
`

const GigInfo = styled.div`
    margin-bottom: 1rem;

    h3{
        margin: 0;
        font-size: 1.2rem;
        font-weight: bold;
    }

    @media (min-width: 768px){
        margin-bottom: 0;
    }
`

const ButtonLink = styled.a`
    border: 3px solid #9A9393;
    padding: .5em 3em;
    color: #9A9393;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    
    @media (min-width: 768px){
        margin-right: 0;
        margin-left: auto;
    }
`

const Gig = ({venue, location, time, date}) => (
    <GigStyled>
        <GigDate>{date}</GigDate>
        <GigInfo>
        <h3>{venue} - {time}</h3>
        <p>{location}</p>
        </GigInfo>
        <ButtonLink href="#">
            Event
        </ButtonLink>
        
    </GigStyled>
);


const Gigs = () => {
    return(
    <Section colour="beige" id="gigs" align="center">
        <H2 uppercase>Gigs</H2>
        <GigsStyled>
        {/* <Gig date="27.07.2022" time="21:15" location="London" venue="The Royal Albert Hall" />
        <Gig date="29.07.2022" time="21:15" location="New York" venue="Madison Square Garden" />
        <Gig date="31.07.2022" time="21:15" location="Los Angeles" venue="The LA Forum" /> */}
        <P>There are currently no gigs scheduled</P>
        </GigsStyled>
    </Section>)
}

export default Gigs;

Gig.propTypes = {
    date: PropTypes.string,
    time: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
}
  
Gig.defaultProps = {
    title: ``
}