import React from "react"
import styled from 'styled-components'
import {P, H2} from './type';

import Section from './section';

export default function About({}){
    return(
        <Section align="center" id="about">
            <H2 uppercase>ABOUT</H2>
            <P><b>Ben and the Bad Weather are a rock band from London.</b></P>

            <P>The band was formed in 2019 around singer-songwriter Ben Herbertson who has been playing around the London circuit for the last few years.</P>

            <P>Looking to liven up his set he enlisted his brother George Herbertson on the drums and Nathan Scoffield on the Bass and together reinvented the sound of Ben’s songs.</P>

        </Section>
    )
}