import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled, {css} from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Logo from './svgs/batbwLogo.js';

import BackgroundImage from 'gatsby-background-image'

const HEADER_HEIGHT = 80;

const HeroStyled = styled.div`
    position: relative;
    height: calc(100vh - ${HEADER_HEIGHT}px);
    overflow-y: hidden;
    overflow-x: hidden;
    
`

const LogoContainer = styled.div`
    z-index: 100;
    position: absolute;
    max-width: 400px;
    display:block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg{
        width: 100%;
    }
`

const absoluteFill = css`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; // or whatever
`

const StyledBackgroundImage = styled(BackgroundImage)`
    ${absoluteFill}
    background-position: 50% 80px  ;
    background-attachment: fixed;
    background-size: cover;
`

const Hero = ({img}) => {
    

    return(
    <HeroStyled>
        
        <LogoContainer>
            <Logo />
        </LogoContainer>

        <StyledBackgroundImage Tag="div" fluid={img.childImageSharp.fluid} />
       
    </HeroStyled>)
}

export default Hero;

