import React from "react"
import styled from 'styled-components'
import {P, H2, H3, Small} from './type';
import Facebook from './svgs/facebook';
import Twitter from './svgs/twitter';
import Insta from './svgs/instagram';

import Section from './section';

import Mailchimp from './mailchimp'

const ContactSection = styled.div`
    padding-bottom: 3rem;
`

const StyledLink = styled.a`
    color: #fff;
    text-decoration: none;
`

const SocialLink = styled.a`
    padding: 0 .5rem;
`


export default function Contact({img}){

    const mailchimpUrl = `https://benandthebadweather.us10.list-manage.com/subscribe/post?u=c3898b2e8e770e8157d481c79&amp;id=4a9af03c32`;

    return(
        <Section align="center" colour="darkBlue" id="contact" img={img}>
            <H2 uppercase>Contact</H2>
            <ContactSection>
                <H3>Email</H3>
                <StyledLink href="mailto:info@benandthebadweather.com">info@benandthebadweather.com</StyledLink>
            </ContactSection>
            <ContactSection>
                <H3>Social</H3>
                <SocialLink href="https://www.instagram.com/benandthebadweatherband/" target="_blank">
                    <Insta />
                </SocialLink>
                <SocialLink href="https://www.facebook.com/benandthebadweather" target="_blank">
                    <Facebook />
                </SocialLink>
                <SocialLink href="https://twitter.com/ndthbdwthr" target="_blank">
                    <Twitter />
                </SocialLink>
            </ContactSection>
            <ContactSection>
                <H3>Mailing List</H3>
                <Small>Sign up to our mailing list to recive updates on band related news and upcoming shows</Small>
                <Mailchimp url={mailchimpUrl} />
            </ContactSection>
        </Section>
    )
}