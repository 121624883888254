import PropTypes from "prop-types"
import React from "react";
import Section from './section';

const FullImage = ({img}) => {
    
    return(
        <Section img={img} fullHeight/>
    )
}

export default FullImage;

