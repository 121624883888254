import React from "react"
import styled from 'styled-components'
import {P, H2, H3} from './type';


const InlineForm = styled.form`
    margin: 0 auto;
    position: relative;

    input{
        background: #413E4F;
        border: 3px solid #ffffff;
        flex-grow: 1;
        margin-right: 1rem;
        color: #ffffff;
        padding: 0 1rem;
        height: 3rem;
        margin-bottom: 1rem;
        width: 100%;
    }

    @media (min-width: 768px){
        display: flex;
        width: 80%;
        input{
            width: auto;
        }
    }
`

const SubmitButton = styled.button`
    border: 3px solid #ffffff;
    padding: .5em 3em;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    background: #413E4F;
    text-transform: uppercase;
    display: block;
    height: 3rem;
    width: 100%;

    @media (min-width: 768px){
        width: auto;
        
    }
`

export default function Mailchimp({url}){
    return(

        <InlineForm action={url} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
             
            
        <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email address"/>
               
        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups  */}
        <div style={{position: 'absolute', left: -5000+'px'}} aria-hidden="true">
            <input type="text" name="b_c3898b2e8e770e8157d481c79_4a9af03c32" tabindex="-1" value="" />
        </div>
        <SubmitButton type="submit" name="subscribe" id="mc-embedded-subscribe">Subscribe</SubmitButton>
        </InlineForm>
    )
}
