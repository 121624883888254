import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.5"
      height="31.5"
      viewBox="0 0 31.5 31.5"
    >
      <path
        fill="#fff"
        d="M28.125 2.25H3.375A3.375 3.375 0 000 5.625v24.75a3.375 3.375 0 003.375 3.375h9.65V23.041H8.6V18h4.43v-3.842c0-4.37 2.6-6.784 6.586-6.784a26.836 26.836 0 013.9.34V12h-2.2a2.521 2.521 0 00-2.842 2.723V18h4.836l-.773 5.041h-4.062V33.75h9.65a3.375 3.375 0 003.375-3.375V5.625a3.375 3.375 0 00-3.375-3.375z"
        data-name="Icon awesome-facebook-square"
        transform="translate(0 -2.25)"
      ></path>
    </svg>
  );
}

export default Icon;
