import React, {useState, useEffect, useRef} from "react"
import styled, {css} from 'styled-components'
import PropTypes from "prop-types"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import { Parallax, Background } from 'react-parallax';

const HEADER_HEIGHT = 80;

const SectionStyled = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    background-color: ${p => p.img ?'': p.colour.bakground };
    color: ${p => p.colour.text};
    position: relative;
    min-height: calc((100vw * 0.7));

    @media (min-width: 768px){
        padding: 4rem;
        min-height: ${p => p.fullHeight ? `calc(100vh - ${HEADER_HEIGHT}px)` : 0};
    }
`

const SectionContent = styled.div`
    width: 960px;
    text-align: ${p => p.align};
    z-index: 10;
`


const absoluteFill = css`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; // or whatever
`

const StyledBackgroundImage = styled(BackgroundImage)`
    ${absoluteFill}
    background-position: 50% 80px  ;
    background-attachment: fixed;
    background-size: cover;
`

export default function Section({colour, children, full, align, img, ...rest}){

    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [translateY, setTranslateY] = useState(0);

    const backgroudRef = React.createRef();

    // useEffect(() => {
    //     function onScroll() {
    //         let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
    //         if (currentPosition > scrollTop) {
    //             // downscroll code
    //             setScrolling(false);
    //         } else {
    //             // upscroll code
    //             setScrolling(true);
    //         }

    //         setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    //     }

        

    //     window.addEventListener("scroll", onScroll);
    //     return window.addEventListener("scroll", onScroll);
    // }, [scrollTop]);

    // useEffect(() => {
    //     if(backgroudRef.current){
    //         //console.log(backgroudRef.current);
    //         const { offsetTop, offsetHeight } = backgroudRef.current;
    //         const diff = scrollTop - offsetTop;
    //         const ratio = diff / offsetHeight * 100;

    //         setTranslateY((-ratio + offsetHeight/2) - HEADER_HEIGHT)
    //     }
    // }, [scrollTop, backgroudRef])

    console.log(translateY);

    const colours = {
        darkBlue: {
            bakground: '#413E4F',
            text: '#FFFFFF'
        },
        beige:{
            bakground: '#D5CBC0',
            text: '#413E4F'
        },
        default: {
            bakground: '#FFFFFF',
            text: '#413E4F'
        }
    }


    const content = (
        <SectionContent align={align}>
            {children}
        </SectionContent>
    )

    return(
        <SectionStyled colour={colours[colour]} ref={backgroudRef} img={img} {...rest}>
            {img ? 
                <StyledBackgroundImage Tag="div" backgroundPos={translateY} fluid={img.childImageSharp.fluid} />
            : null }
            {content}  
        </SectionStyled>
    )
}

SectionStyled.propTypes = {
    colour: PropTypes.string,
}
  
SectionStyled.defaultProps = {
    colour: `default`
}

SectionContent.propTypes = {
    align: PropTypes.string,
}

SectionContent.defaultProps = {
    align: `left`,
}