import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Gigs from "../components/gigs"
import About from "../components/about"
import FullImage from "../components/fullImage"
import VideoSection from "../components/videoSection"
import Contact from "../components/contact"



const IndexPage = () => {
  const data = useStaticQuery(graphql`
        query {
          background: file(relativePath: { eq: "website_bg.jpg" }) {
            childImageSharp {
                fluid(quality: 100) {
                ...GatsbyImageSharpFluid,
                
                }
            }
          },
          bandImage: file(relativePath: { eq: "band_image.jpg" }) {
              childImageSharp {
                  fluid(quality: 100) {
                  ...GatsbyImageSharpFluid,
                  
                  }
              }
          }
        }`);

  return(
    <Layout>
      <SEO title="Home" />
      <Hero img={data.background} />
      <VideoSection videoUrl="https://www.youtube.com/embed/m8Ks1dcskTw"/>
      <FullImage img={data.bandImage} />
      <About />
      <Gigs />
      <Contact img={data.background}/>
    </Layout>
  )
}

export default IndexPage
