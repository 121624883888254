import React from "react"
import styled from 'styled-components'
import {P, H2} from './type';

import Section from './section';

const EmbedContainer = styled.div`
    position: relative; 
    padding-bottom: 56.25%; 
    
    overflow: hidden; 
    max-width: 100%;
    
    iframe, object, embed {
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%;
    }
`

export default function VideoSection({videoUrl}){
    return(
        <Section colour="darkBlue" id="media">
            <H2>MEDIA</H2>
            <EmbedContainer className='embed-container'><iframe src={videoUrl} frameborder='0' allowfullscreen></iframe></EmbedContainer>

        </Section>
    )
}